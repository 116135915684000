<template>
  <container class="bg-white">
    <DxGridWithSearch
      :title="title"
      keyExpr="id"
      :dataSource="dataSource"
      :toolbar="toolbar"
      :gridName="type"
      :columns="columns"
      :selection="selection"
    >
    </DxGridWithSearch>
    <modal :status="isModalOpen" @close="isModalOpen = false">
      <form @submit.prevent="handleSubmit" class="form container">
        <div class="row">
          <h2 class="col-12">
            {{ title }}
          </h2>
        </div>

        <!-- Cytology Diagnostic Codes Fields -->
        <div v-if="type == cytologyEntities.CytDiagnosticCodes" class="row">
          <TextInput class="col-12" label="Name" v-model="form.name" name="name" required />

          <SelectInput
            class="col-6 col-md-3"
            label="Type"
            name="cytTypeId"
            required
            v-model="form.cytTypeId"
            :items="cytologyTypes"
          ></SelectInput>
          <TextInput class="col-6 col-md-3" label="Code" v-model="form.code" name="code" required />
          <SelectInput
            class="col-6 col-md-3"
            label="Code Group"
            required
            v-model="form.cytCodeGroupId"
            name="cytCodeGroupId"
            :items="cytCodeGroups"
          />
          <SelectInput
            class="col-6 col-md-3"
            label="Review Type"
            v-model="form.cytReviewTypeId"
            required
            name="cytReviewTypeId"
            :items="cytReviewTypes"
          />

          <TagInput
            class="col-12 col-md-6"
            name="Icd Codes"
            label="Icd Codes"
            required
            v-model="form.icdCodes"
            :dataSource="icdCodesStore"
          />
          <NumberInput
            class="col-6 col-md-3"
            label="Severity"
            min="0"
            default="0"
            v-model="form.severityLevel"
            name="severityLevel"
            required
          />
          <SelectInput
            class="col-6 col-md-3"
            label="Discrepancy Notification"
            name="discrepancyNotification"
            required
            v-model="form.discrepancyNotification"
            :items="yesNo"
          >
          </SelectInput>
          <div class="col-12 mt-4">
            <h3>Formatted Text</h3>
            <BaseEditor required v-model="form.formattedText" name="formattedText" />
          </div>
        </div>

        <!-- Cytology Questions Fields -->
        <div v-if="type == cytologyEntities.CytQuestions" class="row">
          <NumberInput
            class="col-6"
            label="Sequence Number"
            v-model="form.seqNum"
            default="1"
            name="seqNum"
            required
          />
          <SelectInput
            class="col-6"
            label="Question Type"
            name="cytQuestionTypeId"
            v-model="form.cytQuestionTypeId"
            :items="cytQuestionTypes"
            required
          />
          <TextInput
            class="col-12"
            label="Display Text"
            v-model="form.displayText"
            name="displayText"
            required
          />
          <SelectInput
            class="col-3"
            label="Is Response Required"
            name="isResponseRequired"
            v-model="form.isResponseRequired"
            :items="yesNo"
          />
          <fieldset
            v-if="form.cytQuestionTypeId === listQuestionType"
            class="col-9"
            label="Options"
            required
          >
            <legend>Options</legend>
            <dx-data-grid :toolbar="subToolbar" :columns="subCols" :dataSource="form.options" />
            <modal :status="isSubModalOpen" @close="isSubModalOpen = false">
              <form class="container" @submit.prevent="handleSubmitSubForm">
                <div class="row">
                  <h2 class="col-12">Add Option</h2>
                  <TextInput
                    required
                    class="col-8"
                    label="Option Text"
                    v-model="subform.displayName"
                    name="displayName"
                  />
                  <button class="col offset-9 btn btn-primary" type="submit">Save</button>
                </div>
              </form>
            </modal>
          </fieldset>
        </div>

        <!-- Cytology Diagnostic Macros Fields -->
        <div v-if="type == cytologyEntities.CytDiagnosticMacros" class="row">
          <TextInput
            class="col-6"
            label="Display Name"
            v-model="form.displayName"
            name="displayName"
            required
          />
          <SelectInput
            class="col-6"
            label="Type"
            name="cytTypeId"
            v-model="form.cytTypeId"
            :items="cytologyTypes"
          ></SelectInput>
          <TextAreaInput
            class="col-12"
            label="Description"
            v-model="form.description"
            name="description"
            required
          />
          <fieldset class="col-9" label="Questions">
            <legend>Codes</legend>
            <dx-data-grid
              :row-dragging="rowDrag"
              keyExpr="seqNum"
              :toolbar="subToolbar"
              :columns="subCols"
              :dataSource="form.codes"
            />
            <modal :status="isSubModalOpen" @close="isSubModalOpen = false">
              <form class="container" @submit.prevent="handleSubmitSubForm">
                <div class="row">
                  <h2 class="col-12">Add Code</h2>
                  <SelectInput
                    required
                    class="col-6"
                    label="Diagnosis Code"
                    v-model="subform.cytDiagnosticCodeId"
                    name="cytDiagnosticCodeId"
                    :dataSource="mainDiagnosisCodes"
                  />
                  <NumberInput
                    required
                    class="col-6"
                    default="1"
                    label="Sequence Number"
                    :min="1"
                    v-model="subform.seqNum"
                    name="seqNum"
                  />
                  <button class="col offset-9 btn btn-primary" type="submit">Save</button>
                </div>
              </form>
            </modal>
          </fieldset>
        </div>
        <!-- Cytology Molecular Results Fields && Cytology Molecular Panels Fields -->
        <div
          v-if="
            type == cytologyEntities.CytMolecResults ||
            type == cytologyEntities.CytMolecPanels ||
            type == cytologyEntities.CytMolecTests
          "
          class="row"
        >
          <TextInput
            class="col-8"
            required
            label="Display Text"
            v-model="form.displayText"
            name="displayText"
          />
        </div>

        <!-- Cytology Panels Fields -->
        <div class="row" v-if="type == cytologyEntities.CytMolecPanels">
          <tag-input
            class="col-4"
            label="Billing Tx Codes"
            v-model="form.billingTransactionCodes"
            name="results"
            required
            displayExpr="code"
            :dataSource="billingCodesStore"
          />

          <TagInput
            class="col-12 col-md-6"
            name="Icd Codes"
            label="Icd Codes"
            required
            v-model="form.icdCodes"
            :dataSource="icdCodesStore"
          />
          <fieldset class="col-6" label="Molecular Tests">
            <legend>Tests</legend>
            <dx-data-grid
              :row-dragging="rowDrag"
              keyExpr="seqNum"
              :dataSource="form.tests"
              :toolbar="subToolbar"
              :columns="subCols"
            />
            <modal :status="isSubModalOpen" @close="isSubModalOpen = false">
              <form class="container" @submit.prevent="handleSubmitSubForm">
                <div class="form-row">
                  <h2 class="col-12">Add Test</h2>
                  <SelectInput
                    class="col-6"
                    label="Molecular Results"
                    name="cytMolecTestId"
                    required
                    v-model="subform.cytMolecTestId"
                    :dataSource="molecTestsSearch"
                  />
                  <NumberInput
                    class="col-6"
                    label="Seq Num"
                    v-model="subform.seqNum"
                    default="1"
                    name="seqNum"
                    required
                  />
                  <button class="col offset-11 btn btn-primary" type="submit">Save</button>
                </div>
              </form>
            </modal>
          </fieldset>
        </div>
        <!-- Cytology Triggers Fields -->
        <div class="row" v-if="type == cytologyEntities.CytTriggers">
          <SelectInput
            class="col-6"
            label="Provider"
            name="providerId"
            multiple
            required
            v-model="form.providerId"
            :displayExpr="providerExpr"
            :dataSource="providersSearch"
          />
          <SelectInput
            class="col-6"
            label="Diagnostic Code"
            name="cytDiagnosticCodeId"
            v-model="form.cytDiagnosticCodeId"
            required
            :dataSource="cytologyCodesSearch"
          />
          <SelectInput
            class="col-6"
            label="Molecular Result"
            name="cytMolecResultId"
            v-model="form.cytMolecResultId"
            :dataSource="molecResultsSearch"
            required
          />
          <SelectInput
            class="col-6"
            label="Molecular Test"
            name="cytMolecTestId"
            v-model="form.cytMolecTestId"
            :dataSource="molecTestsSearch"
            required
          />
          <TagInput
            class="col-6"
            label="Tests To Trigger"
            name="tests"
            required
            v-model="form.tests"
            :dataSource="molecTestsSearch"
          />

          <NumberInput class="col-6" label="Min Age" required v-model="form.minAge" name="minAge" />
          <NumberInput class="col-6" label="Max Age" required v-model="form.maxAge" name="maxAge" />
          <NumberInput
            class="col-6"
            label="Min Severity"
            v-model="form.minSeverity"
            name="minSeverity"
            default="0"
            required
          />
          <NumberInput
            class="col-6"
            label="Max Severity"
            v-model="form.maxSeverity"
            name="maxSeverity"
            default="0"
            required
          />

          <NumberInput
            class="col-6"
            label="Wait Months"
            v-model="form.waitMonths"
            name="waitMonths"
            default="0"
            required
          />
        </div>
        <!-- Cytology Molecular Tests Fields -->
        <div class="row wrap" v-if="type === cytologyEntities.CytMolecTests">
          <number-input
            class="col-4"
            label="Seq Num"
            v-model="form.seqNum"
            name="seqNum"
            default="1"
            required
          />
          <text-input class="col-4" label="Method" v-model="form.method" name="method" required />
          <text-input
            class="col-4"
            label="Composition"
            v-model="form.composition"
            name="composition"
            required
          />
          <select-input
            class="col-4"
            label="Normal Result"
            required
            v-model="form.normalResultId"
            name="normalResultId"
            :dataSource="cytologyResults"
          />
          <tag-input
            class="col-4"
            label="Billing Tx Codes"
            v-model="form.billingTransactionCodes"
            name="results"
            required
            displayExpr="code"
            :dataSource="billingCodesStore"
          />
          <fieldset class="col-9" label="Responses">
            <legend>Responses</legend>
            <dx-data-grid :toolbar="subToolbar" :columns="subCols" :dataSource="form.results" />
            <modal :status="isSubModalOpen" @close="isSubModalOpen = false">
              <form class="container" @submit.prevent="handleSubmitSubForm">
                <div class="row wrap">
                  <h2 class="col-12">Add Response</h2>
                  <SelectInput
                    class="col-6 col-md-4"
                    label="Molec Result"
                    required
                    name="cytMolecResultId"
                    v-model="subform.cytMolecResultId"
                    :dataSource="molecResultsSearch"
                  />
                  <SelectInput
                    class="col-6 col-md-4"
                    :dataSource="icdCodesStore"
                    v-model="subform.icdCodeId"
                    required
                    label="Icd Code"
                    name="icdCodes"
                  />
                </div>
                <div class="row"></div>
                <div class="row">
                  <div class="col-12">
                    <h3>Explanation Text</h3>
                    <BaseEditor required v-model="subform.explanation" name="explanation" />
                  </div>
                </div>
                <div class="row">
                  <button class="col offset-9 btn btn-primary" type="submit">Save</button>
                </div>
              </form>
            </modal>
          </fieldset>
        </div>
        <div class="row" v-if="hasEffectiveDates">
          <DatePicker
            class="col-6"
            label="Effective Date"
            v-model="form.effectiveOn"
            name="effectiveDate"
          />
          <DatePicker class="col-6" label="Expiry Date" v-model="form.expiryOn" name="expiryOn" />
        </div>
        <div class="row justify-content-end mt-4">
          <button class="btn btn-primary" type="submit">Save</button>
        </div>
      </form>
    </modal>
  </container>
</template>

<script>
import DxGridWithSearch from "@/components/common/DxGridWithSearch.vue";
import cytologyService, { CytologyEntities } from "@/services/cytology.js";
import modal from "@/components/common/Modal.vue";
import {
  CytTypeEnum,
  enumToDropDown,
  CytQuestionTypeEnum,
  CytCodeGroupEnum,
  CytResultTypeEnum,
  AuditLogItems,
  CytReviewTypeEnum
} from "@/modules/enums";
import DataSource from "devextreme/data/data_source";
import ProviderService from "@/services/providers";
import billingTxCode from "@/services/Billing";
import DropdownApi from "@/services/dropdown";
import Container from "../common/Container.vue";
import { createLogComment, createLogItem } from "@/modules/helpers";
import { AuditLogApi } from "@/services";
import { cloneDeep } from "lodash";
export default {
  components: {
    DxGridWithSearch,
    modal,
    TextInput: () => import("@/components/common/TextInput.vue"),
    SelectInput: () => import("@/components/common/SelectInput.vue"),
    DatePicker: () => import("@/components/common/DatePicker.vue"),
    TextAreaInput: () => import("@/components/TextAreaInput.vue"),
    NumberInput: () => import("@/components/common/NumberInput.vue"),
    TagInput: () => import("@/components/common/TagInput.vue"),
    DxDataGrid: () => import("devextreme-vue/data-grid"),
    Container,
    BaseEditor: () => import("@/components/common/BaseEditor.vue")
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  name: "CytlogyCodesBrowse",
  mounted() {
    this.$on("reload", () => {
      this.dataSource.reload();
    });
  },
  beforeDestroy() {
    this.$off("reload");
  },
  computed: {
    tests() {
      if (this.form.tests) {
        return [...this.form?.tests].sort((a, b) => a.seqNum - b.seqNum);
      }
      return [];
    },
    selection() {
      return {
        mode: "none"
      };
    },
    defaultForm() {
      switch (this.type) {
        case CytologyEntities.CytDiagnosticCodes:
          return {
            icdCodes: [],
            mandatoryModifiers: []
          };
        case CytologyEntities.CytQuestions:
          return {
            options: []
          };
        case CytologyEntities.CytMolecPanels:
          return {
            tests: []
          };
        case CytologyEntities.CytMolecTests:
          return {
            results: []
          };
        default:
          return {};
      }
    },

    toolbar() {
      return {
        items: [
          {
            name: "test",
            widget: "dxButton",
            visible: true,
            options: {
              icon: "add",
              onClick: () => {
                this.isModalOpen = true;
                this.form = {
                  effectiveOn: new Date(),
                  ...this.defaultForm
                };
              }
            }
          }
        ]
      };
    },
    title() {
      switch (this.type) {
        case CytologyEntities.CytDiagnosticCodes:
          return "Cytology Diagnostic Codes ";
        case CytologyEntities.CytDiagnosticMacros:
          return "Cytology Diagnostic Macros";
        case CytologyEntities.CytQuestions:
          return "Cytology Questions";
        case CytologyEntities.CytMolecResults:
          return "Cytology Molecular Results";
        case CytologyEntities.CytMolecPanels:
          return "Cytology Molecular Panels";
        case CytologyEntities.CytTriggers:
          return "Cytology Triggers";
        case CytologyEntities.CytMolecTests:
          return "Cytology Molecular Tests";
        default:
          return "";
      }
    },
    dataSource() {
      const options = {
        store: cytologyService.createSearch(this.type)
      };

      return new DataSource(options);
    },
    subActions() {
      return [
        {
          type: "buttons",
          caption: "Actions",
          buttons: [
            {
              hint: "Edit",
              icon: "edit",
              onClick: this.editSubRow
            },
            {
              hint: "Delete",
              icon: "trash",
              onClick: this.deleteSubRow
            }
          ]
        }
      ];
    },
    subCols() {
      let cols = [];

      switch (this.type) {
        case CytologyEntities.CytMolecTests:
          cols = [
            {
              dataField: "explanation",
              caption: "Explanation",
              encodeHtml: false
            },
            {
              dataField: "cytMolecResultId",
              caption: "Molecular Test",
              lookup: {
                dataSource: this.molecResultsSearch.store(),
                valueExpr: "id",
                displayExpr: "displayText"
              }
            }
          ];
          break;
        case CytologyEntities.CytDiagnosticMacros:
          cols = [
            {
              dataField: "seqNum",
              caption: "Sequence No.",
              sortIndex: 0,
              sortOrder: "asc"
            },
            {
              dataField: "cytDiagnosticCodeId",
              caption: "Diagnostic Code",
              lookup: {
                dataSource: this.cytologyCodesSearch,
                valueExpr: "id",
                displayExpr: "name"
              }
            }
          ];
          break;
        case CytologyEntities.CytMolecPanels:
          cols = [
            {
              dataField: "seqNum",
              caption: "Sequence No.",
              sortIndex: 0,
              sortOrder: "asc"
            },
            {
              dataField: "cytMolecTestId",
              caption: "Molecular Test",
              lookup: {
                dataSource: this.molecTestsSearch,
                valueExpr: "id",
                displayExpr: "displayText"
              }
            }
          ];
          break;
        case CytologyEntities.CytQuestions:
          cols = [
            {
              dataField: "displayName",
              caption: "Option Text"
            }
          ];
          break;

        default:
          cols = [];
          break;
      }
      return [...this.subActions, ...cols];
    },
    actions() {
      return [
        {
          type: "buttons",
          caption: "Actions",
          buttons: [
            {
              hint: "Edit",
              icon: "edit",
              onClick: this.editRow
            }
            // ! TODO: Backend needs deletion route.
            // {
            //   hint: "Delete",
            //   icon: "trash",
            //   onClick: this.deleteRow
            // }
          ]
        }
      ];
    },
    expirationFields() {
      return [
        {
          dataField: "effectiveOn",
          caption: "Effective Date",
          dataType: "date"
        },
        {
          dataField: "expiryOn",
          caption: "Expiry Date",
          dataType: "date"
        }
      ];
    },
    columns() {
      let cols = [];
      switch (this.type) {
        case CytologyEntities.CytDiagnosticCodes:
          cols = [
            {
              dataField: "name",
              caption: "Name"
            },
            { dataField: "cytType", caption: "Cytology Type" },
            {
              dataField: "cytCodeGroup",
              caption: "Code Group"
            },
            {
              dataField: "code",
              caption: "Code"
            },
            {
              dataField: "severityLevel",
              caption: "Severity Level"
            },
            {
              dataField: "discrepancyNotification",
              caption: "Discrepancy Notification",
              lookup: {
                dataSource: this.yesNo,
                valueExpr: "id",
                displayExpr: "name"
              }
            }
          ];
          break;
        case CytologyEntities.CytDiagnosticMacros:
          cols = [
            {
              dataField: "displayName",
              caption: "Name"
            },
            {
              dataField: "description",
              caption: "Name"
            },
            {
              dataField: "cytType",
              caption: "Cytology Type"
            }
          ];
          break;
        case CytologyEntities.CytQuestions:
          cols = [
            {
              dataField: "seqNum",
              caption: "Sequence Number"
            },
            {
              dataField: "cytQuestionType",
              caption: "Question Type"
            },
            {
              dataField: "displayText",
              caption: "Display Text"
            },
            {
              dataField: "isResponseRequired",
              caption: "Required"
            }
          ];
          break;
        case CytologyEntities.CytTriggers:
          cols = [
            {
              dataField: "providerId",
              caption: "Provider",
              lookup: {
                dataSource: ProviderService.searchProviders,
                valueExpr: "id",
                displayExpr: this.providerExpr
              }
            },
            {
              dataField: "cytDiagnosticCodeId",
              caption: "Diagnostic Code",
              lookup: {
                dataSource: cytologyService.createSearch(CytologyEntities.CytDiagnosticCodes),
                valueExpr: "id",
                displayExpr: "name"
              }
            },
            {
              dataField: "cytMolecResultId",
              caption: "Molecular Result",
              lookup: {
                dataSource: cytologyService.createSearch(CytologyEntities.CytMolecResults),
                valueExpr: "id",
                displayExpr: "displayText"
              }
            },
            {
              dataField: "cytMolecTestId",
              caption: "Molecular Test",
              lookup: {
                dataSource: cytologyService.createSearch(CytologyEntities.CytMolecTests),
                valueExpr: "id",
                displayExpr: "displayText"
              }
            },


            {
              dataField: "maxAge"
            },
            {
              dataField: "maxSeverity"
            },
            {
              dataField: "minSeverity"
            },

            {
              dataField: "waitMonths"
            }
          ];
          break;
        case CytologyEntities.CytMolecTests:
          cols = [
            { dataField: "displayText", caption: "Name" },
            {
              dataField: "seqNum",
              caption: "Sequence Number"
            },
            {
              dataField: "method",
              caption: "Method"
            },
            { dataField: "composition", caption: "Composition" },
            {
              dataField: "normalResultId",
              caption: "Normal Result",
              lookup: {
                dataSource: this.cytologyResults,
                valueExpr: "id",
                displayExpr: "displayText"
              }
            }
          ];
          break;
        default:
          cols = [{ dataField: "displayText", caption: "Name" }];
          break;
      }
      return [...this.actions, ...cols, ...this.expirationFields];
    },
    hasEffectiveDates() {
      return this.columns.some(c => c.dataField === "effectiveOn");
    }
  },
  data() {
    const topCodes = [
      CytCodeGroupEnum.Adequacy,
      CytCodeGroupEnum.Diagnosis,
      CytCodeGroupEnum.Comment,
      CytCodeGroupEnum.Hormonal,
      CytCodeGroupEnum.Recommendation
    ];
    return {
      rowDrag: {
        allowReordering: true,
        dropFeedbackMode: "push",
        onReorder: this.handleMove
      },
      cytReviewTypes: [
        { id: CytReviewTypeEnum.None, displayName: "None" },
        { id: CytReviewTypeEnum.Reviewer, displayName: "Reviewer" },
        { id: CytReviewTypeEnum.Pathologist, displayName: "Pathologist" }
      ],
      subToolbar: {
        items: [
          {
            name: "add",
            widget: "dxButton",
            options: {
              icon: "add",
              onClick: () => {
                this.isSubModalOpen = true;
                this.subform = {};
              }
            }
          }
        ]
      },
      subform: {},
      isSubModalOpen: false,
      cytCodeGroups: enumToDropDown(CytCodeGroupEnum),
      icdCodesStore: new DataSource({
        store: DropdownApi.searchIcdCodes
      }),
      mainDiagnosisCodes: new DataSource({
        store: cytologyService.createSearch(CytologyEntities.CytDiagnosticCodes),
        //Only the mainTypes
        filter: topCodes.map(code => ["cytCodeGroupId", "=", code]).join(" or ")
      }),
      cytologyCodesSearch: cytologyService.createSearch(CytologyEntities.CytDiagnosticCodes),
      molecResultsSearch: new DataSource({
        store: cytologyService.createSearch(CytologyEntities.CytMolecResults)
      }),

      molecTestsSearch: cytologyService.createSearch(CytologyEntities.CytMolecTests),
      billingCodesStore: billingTxCode.searchStore,
      yesNo: [
        { id: true, name: "Yes" },
        { id: false, name: "No" }
      ],
      cytResultType: enumToDropDown(CytResultTypeEnum),
      providersSearch: new DataSource({
        store: ProviderService.searchProviders
      }),
      cytologyEntities: CytologyEntities,
      cytQuestionTypes: enumToDropDown(CytQuestionTypeEnum),
      listQuestionType: CytQuestionTypeEnum.List,
      cytologyTypes: enumToDropDown(CytTypeEnum),
      cytologyResults: cytologyService.createSearch(CytologyEntities.CytMolecResults),
      isEditing: false,
      isModalOpen: false,
      form: {},
      originalDetails: {}
    };
  },
  watch: {
    isSubModalOpen(val) {
      if (!val) {
        this.subform = {};
        this.isEditing = false;
      }
    },
    isModalOpen(val) {
      if (!val) {
        this.form = {};
        this.isEditing = false;
      }
    }
  },
  methods: {
    editSubRow(e) {
      const { row } = e;
      this.subform = row.data;
      this.isEditing = true;
      this.isSubModalOpen = true;
    },

    handleSubmitSubForm() {
      const isNew = !this.subform?.id;
      switch (this.type) {
        case CytologyEntities.CytQuestions:
          {
            const error = this.validateQuestion(this.subform);
            if (error) {
              window.notify(error, "error");
              return;
            }
            if (isNew) {
              this.form.options = [...(this.form.options || []), this.subform];
            } else {
              this.form.options = this.form.options.map(option =>
                option.id === this.subform.id ? this.subform : option
              );
            }
          }
          break;
        case CytologyEntities.CytMolecPanels:
          {
            const error = this.validateMolecPanelTest(this.subform);
            if (error) {
              window.notify(error, "error");
              return;
            }
            if (isNew) {
              this.form.tests = [...(this.form.tests || []), this.subform];
            } else {
              this.form.tests = this.form.tests.map(test =>
                test.id === this.subform.id ? this.subform : test
              );
            }
          }
          break;
        case CytologyEntities.CytMolecTests:
          {
            const error = this.validateMolecResponses(this.subform);
            if (error) {
              window.notify(error, "error");
              return;
            }
            if (isNew) {
              this.form.results = [...(this.form.results || []), this.subform];
            } else {
              this.form.results = this.form.results.map(test =>
                test.id === this.subform.id ? this.subform : test
              );
            }
          }
          break;
        case CytologyEntities.CytDiagnosticMacros:
          {
            const error = this.validateMacroCode(this.subform);
            if (error) {
              window.notify(error, "error");
              return;
            }
            if (isNew) {
              const itemSet = new Set(this.form.codes.map(code => code.cytDiagnosticCodeId));
              if (itemSet.has(this.subform.cytDiagnosticCodeId)) {
                window.notify("Code already exists", "error");
                return;
              }
              this.form.codes = [...(this.form.codes || []), this.subform];
            } else {
              this.form.codes = this.form.codes.map(code =>
                code.id === this.subform.id ? this.subform : code
              );
            }
          }

          break;
        default:
          break;
      }

      this.subform = {};
      this.isSubModalOpen = false;
    },
    validateQuestion(details) {
      const { displayName, id } = details;
      const isNew = !id;
      let current = [...this.form.options];
      if (!isNew) {
        current = this.form.options.filter(e => e.id !== id);
      }

      const isDupeDisplayNameIdx = current.findIndex(option => option.displayName === displayName);
      if (isDupeDisplayNameIdx > -1) {
        return `This option text is already in use.`;
      }

      return null;
    },

    validateMolecResponses(details) {
      const { cytMolecResultId, id, icdCodeId } = details;
      const isNew = !id;
      let current = [...this.form.results];
      if (!isNew) {
        current = this.form.results.filter(e => e.id !== id);
      }
      const isDupeResultIdx = current.findIndex(test => test.cytMolecResultId === cytMolecResultId);
      if (isDupeResultIdx > -1) {
        const dupeItem = this.form.results[isDupeResultIdx];
        if (dupeItem?.id === this.subform?.id) {
          return null;
        }
        return `This result is already in use.`;
      }
      const isDupeIcdCodeIdx = current.findIndex(test => test.icdCodeId === icdCodeId);
      if (isDupeIcdCodeIdx > -1) {
        return `This ICD code is already in use.`;
      }
      return null;
    },
    validateMolecPanelTest(details) {
      const { seqNum, cytMolecTestId, id } = details;
      const isNew = !id;
      let current = [...this.form.tests];
      if (!isNew) {
        current = this.form.tests.filter(e => e.id !== id);
      }
      const maxSeqNum =
        this.form.tests.reduce((max, test) => (Number(test.seqNum) > max ? test.seqNum : max), 0) +
        1;
      if (isNaN(seqNum)) {
        return "Sequence number must be a number";
      }
      const num = Number(seqNum);
      if (num > maxSeqNum) {
        return `Sequence number must be less than the maximum sequence number ${maxSeqNum}`;
      }
      const isDupeSeqNumIdx = current.findIndex(test => test.seqNum === seqNum);
      if (isDupeSeqNumIdx > -1) {
        const dupeItem = this.form.tests[isDupeSeqNumIdx];
        if (dupeItem?.id === this.subform?.id) {
          return null;
        }
        return `This sequence number is already in use`;
      }
      const isDupeResultIdx = current.findIndex(test => test.cytMolecTestId === cytMolecTestId);
      if (isDupeResultIdx > -1) {
        const dupeItem = this.form.tests[isDupeResultIdx];
        if (dupeItem?.id === this.subform?.id) {
          return null;
        }
        return `This result is already in use on sequence number ${dupeItem.seqNum}`;
      }

      return null;
    },

    validateMacroCode(details) {
      const { seqNum, cytDiagnosticCodeId } = details;
      const maxSeqNum =
        this.form.codes.reduce((max, code) => (code.seqNum > max ? code.seqNum : max), 0) + 1;
      const isNew = !details.id;
      let current = [...this.form.codes];
      if (!isNew) {
        current = this.form.codes.filter(e => e.id !== details.id);
      }
      // Check if its a number
      if (isNaN(seqNum)) {
        return "Sequence number must be a number";
      }
      const num = Number(seqNum);
      if (num > maxSeqNum) {
        return `Sequence number must be less than the maximum sequence number ${maxSeqNum}`;
      }
      // Check if seqNum is already in use
      const isDupeSeqNumIdx = current.findIndex(code => code.seqNum === seqNum);
      if (isDupeSeqNumIdx > -1) {
        return `This sequence number is already in use`;
      }

      const isDupeCodeIdx = current.findIndex(
        code => code.cytDiagnosticCodeId === cytDiagnosticCodeId
      );
      if (isDupeCodeIdx > -1) {
        const { seqNum: seqNumDupe } = this.form.codes[isDupeCodeIdx];
        return `Code already exists on sequence number ${seqNumDupe}`;
      }

      return null;
    },
    deleteSubRow(e) {
      const { row } = e;
      const { data } = row;
      switch (this.type) {
        case CytologyEntities.CytQuestions:
          this.form.options = this.form.options.filter(e => e !== data);
          break;
        case CytologyEntities.CytDiagnosticMacros:
          this.form.codes = this.form.codes.filter(e => e !== data);
          break;
        case CytologyEntities.CytMolecPanels:
          this.form.tests = this.form.tests.filter(e => e !== data);
          break;
        case CytologyEntities.CytMolecTests:
          this.form.results = this.form.results.filter(e => e !== data);
          break;
        default:
          break;
      }
    },
    providerExpr(data) {
      if (!data) return "";
      return `${data.firstName} ${data.lastName} (${data.npi})`;
    },
    handleMove(event) {
      const { toIndex, fromIndex } = event;
      if (toIndex === -1) return;
      if (toIndex === fromIndex) return;

      let items = event.component.getVisibleRows();
      if (items.length) {
        items = items.map(e => e.data);
      }

      items.splice(fromIndex, 1);
      items.splice(toIndex, 0, event.itemData);
      switch (this.type) {
        case CytologyEntities.CytMolecPanels:
          this.form.tests = items.map((e, i) => ({
            ...e,
            seqNum: i + 1
          }));
          break;
        case CytologyEntities.CytDiagnosticMacros:
          this.form.codes = items.map((e, i) => ({
            ...e,
            seqNum: i + 1
          }));
          break;
        default:
          break;
      }
    },
    async editRow(e) {
      const { row } = e;
      const {
        data: { id }
      } = row;
      const details = await cytologyService.getSingleEntity(this.type, id);
      this.originalDetails = cloneDeep(details);
      this.form = details;
      this.isModalOpen = true;
    },
    async deleteRow(e) {
      const confirm = await window.confirm("Are you sure you want to delete this record?");
      if (!confirm) return;
      const { row } = e;
      const {
        data: { id }
      } = row;
      await cytologyService.deleteEntity(this.type, id);
      const auditLogMessage = createLogItem({}, AuditLogItems.ChangeAdmin);
      auditLogMessage.comments = `Deleted the ${this.type} item with ID ${id}. \n ${JSON.stringify(
        row.data,
        null,
        2
      )}`;
      await AuditLogApi.insertLogMessage(auditLogMessage);
      this.dataSource.reload();
    },
    async handleSubmit() {
      try {
        if (this.form.id) {
          await cytologyService.updateEntity(this.type, this.form);
          const logItem = createLogItem({}, AuditLogItems.ChangeAdmin);
          logItem.comments = createLogComment(this.originalDetails, this.form);
          await AuditLogApi.insertLogMessage(logItem);
        } else {
          await cytologyService.createEntity(this.type, this.form);
          const logItem = createLogItem({}, AuditLogItems.CreateAdmin);
          logItem.comments = createLogComment(JSON.stringify(this.form));
          await AuditLogApi.insertLogMessage(logItem);
        }
        this.isModalOpen = false;
        this.dataSource.reload();
      } catch (error) {
        console.log(error);
        window.notify("An error occurred while saving the record", "error");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
